var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"award"},[_c('g-hero',{attrs:{"content":{
    picture: _vm.content.heroPicture,
    title: _vm.content.title,
    companyLogo: _vm.content.logo,
    subtitle: _vm.content.name,
    topic: _vm.content.mainTopic.name,
    category: _vm.content.award
  },"type":"award"}}),_c('g-infos',{attrs:{"content":{
    tags: _vm.content.otherTopic,
    city: _vm.content.city,
    country: _vm.content.country,
    year: _vm.content.year,
    follow: true,
  }}}),_c('g-duo',{attrs:{"content":{
    headline: 'Elevator pitch',
    htmltext: _vm.content.elevatorPitch,
  }}}),_c('g-flexible',{attrs:{"content":_vm.content.flexibleContent}}),(_vm.content.relatedResources)?_c('CardSlider',{attrs:{"content":_vm.content.relatedResources}}):_vm._e(),(_vm.content.relatedEvents)?_c('CardSlider',{attrs:{"content":_vm.content.relatedEvents}}):_vm._e(),(_vm.content.footerCtas)?_c('g-ctas',{attrs:{"content":_vm.content.footerCtas}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }